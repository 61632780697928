<template>
    <Modal
        id="connect-nyala-project-modal"
        ref="connect-nyala-project-modal"
        v-model:open="open"
        title="connect_nyala_project"
        width="xl"
        @submit="submit"
        @cancel="cancel"
    >
        <TextField
            v-model="emptyNyalaProject.external_tokenized_asset_id"
            label="nyala_project_external_tokenized_asset_id"
            name="external_tokenized_asset_id"
            :required="true"
            help="help_nyala_project_external_tokenized_asset_id"
        />

        <TextField
            v-model="emptyNyalaProject.external_project_id"
            label="nyala_project_external_project_id"
            name="external_project_id"
            :required="true"
            help="help_nyala_project_external_project_id"
        />

        <template #actions>
            <MultiActionButton />
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { useMultiActionButton } from '@composables/common'
import { Modal, TextField } from '@src/components'
import { NyalaProject } from '@src/types'
import { useVModels } from '@vueuse/core'
import { computed } from 'vue'

const props = defineProps<{
    open: boolean
    nyalaProject: NyalaProject | null
    disabledFields?: string[] | null
}>()

const emptyNyalaProject = computed(
    () => props.nyalaProject ?? new NyalaProject()
)

const emit = defineEmits(['update:open', 'cancel', 'submit'])

const { open, nyalaProject } = useVModels(props, emit)

const { MultiActionButton } = useMultiActionButton({
    id: 'connect-nyala-project-button',
    actions: [
        {
            label: 'save',
            action: submit,
            disabled: () => disableCreate.value,
        },
    ],
})

function cancel() {
    emit('cancel')
}

function submit() {
    emit('submit')
}

const disableCreate = computed(() => {
    return (
        !nyalaProject.value?.external_project_id ||
        !nyalaProject.value?.external_tokenized_asset_id
    )
})
</script>
