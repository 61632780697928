<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-solid fa-file-signature" />&nbsp;
            {{ $t('contract') }}&nbsp;
            {{ contract.contract_uid }}
        </template>
        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/contracts'"
                icon="fa-list"
                title="contracts"
                @click="toContracts"
            />
            <EditButton
                v-if="userStore.can('update_contract')"
                title="edit"
                @click="edit"
            />
            <DeleteButton
                v-if="userStore.can('delete_contract') && contract.allow_edit"
                @click="handleDelateContract"
            />
            <ActionsMenu
                v-if="
                    (contract.allow_edit && userStore.can('end_contract')) ||
                    interestPaymentsCount > 0
                "
            />
        </template>
        <template #modals>
            <ConnectNyalaModal
                id="connect-nyala-modal"
                ref="connectNyalaModal"
                v-model:nyalaProject="nyalaProjectEdit"
                v-model:open="connectNyalaModalOpen"
                title="manage_nyala_project"
                @submit="connectNyalaProjectToContract"
            />
        </template>

        <SummaryWrapper>
            <ContractSummary />
            <template #sidebar>
                <AssetSummary />
                <NyalaProjectSummary
                    v-if="
                        nyalaProject !== null ||
                        contractAssetConnectedToNyalaProject === true
                    "
                />
            </template>
        </SummaryWrapper>

        <InterestPaymentSection
            v-if="contract.contract_uid"
            :contract-id="contract.contract_uid"
            :asset-currency="assetCurrency"
            :tax-calculation-enabled="contract.tax_calculation_enabled"
            @get-count="getInterestPaymentsCount"
            @update="loadContract"
        />
        <ContractOrdersTable>
            <template #actions
                ><CreateButton
                    title="export_transactions"
                    @click="handleExportTransactions"
            /></template>
        </ContractOrdersTable>
        <ModalWrapper v-if="isModalOpen" />
    </PageWrapper>
</template>

<script setup lang="ts">
import { useContractOrdersTable } from '@composables/contracts/useContractOrdersTable'
import BackButton from '@partials/actions/BackButton.vue'
import DeleteButton from '@partials/actions/DeleteButton.vue'
import EditButton from '@partials/actions/EditButton.vue'
import PageWrapper from '@partials/pages/PageWrapper.vue'
import { NameAndId, SummaryWrapper } from '@src/components'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import EndContactForm from '@src/partials/contracts/EndContactForm.vue'

import moment from 'moment'

import { ModalButtonStyles, useEnum, useModal } from '@composables/common'
import { useActionsMenu, useSummary } from '@src/composables/common'
import { useAssetSummary } from '@src/composables/summaries/useAssetSummary'
import { useNyalaProjectSummary } from '@src/composables/summaries/useNyalaProjectSummary'
import {
    CalculationStartDate,
    ContractEndCause,
    ContractPeriodStart,
    ContractPeriodType,
    ContractType,
    InterestRateCalculationMethod,
    KistaRequestType,
    KistaServiceType,
} from '@src/enums'

import InterestPaymentSection from '@src/partials/contracts/InterestPaymentSection.vue'
import ConnectNyalaModal from '@src/partials/nyalaPlatforms/ConnectNyalaProjectModal.vue'
import { Asset, ContractDetails, ContractOrder, NyalaProject } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { getAsset, getAssets } from '@src/utils/api/assets'
import {
    deleteContract as apiDeleteContract,
    endContract as apiEndContract,
    exportKistaRequest,
    exportTransactions,
    getContract,
    getContractOrders,
} from '@src/utils/api/contracts'
import {
    createNyalaProject as apiCreateNyalaProject,
    detachNyalaProject as apiDetachNyalaProject,
    getNyalaProjectForContract as apiGetNyalaProject,
    isContractAssetConnectedToNyalaProject as apiIsContractAssetConnectedToNyalaProject,
    updateNyalaProject as apiUpdateNyalaProject,
} from '@src/utils/api/nyalaProjects'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { downloadFile } from '@utils/helpers'
import { computed, h, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const userStore = useUserStore()

const { getEnumLabel } = useEnum()
const { ModalWrapper, openModal } = useModal()

const contract = ref<ContractDetails>(new ContractDetails())
const interestPaymentsCount = ref<number>(0)
const assets = ref<Asset[]>([])
const asset = ref<Asset>(new Asset())
const assetCurrency = ref<string | null>(null)
const orders = ref<ContractOrder[]>([])
const ordersCount = ref<number>(0)

const isModalOpen = ref(false)
const nyalaProject = ref<NyalaProject | null>(null)
const nyalaProjectEdit = ref<NyalaProject | null>(null)
const connectNyalaModalOpen = ref<boolean>(false)
const assetContractsAllConnectedToNyalaProject = ref<null>(null)
const contractAssetConnectedToNyalaProject = ref<boolean>(false)
const { NyalaProjectSummary } = useNyalaProjectSummary(
    nyalaProject,
    assetContractsAllConnectedToNyalaProject,
    contractAssetConnectedToNyalaProject
)

const { ActionsMenu } = useActionsMenu(
    {
        id: 'asset-actions-menu',
        align: 'right',
    },
    [
        {
            label: 'end_contract',
            icon: 'fa-calendar-xmark',
            color: 'text-danger-600',
            action: handleEndContract,
            condition: () =>
                !contract.value.contract_end_cause &&
                userStore.can('end_contract'),
        },
        {
            label: 'export_transactions',
            icon: 'fa-money-bill-transfer',
            color: 'text-primary-accent-600',
            action: handleExportTransactions,
            condition: () =>
                !contract.value.contract_end_cause &&
                interestPaymentsCount.value > 0 &&
                userStore.can('end_contract'),
        },
        {
            label: 'export_kista_request_kistama',
            icon: 'fa-section',
            color: 'text-primary-accent-600',
            action: () => handleExportKistaRequest(KistaRequestType.KISTAMA),
            condition: () =>
                contract.value.tax_calculation_enabled &&
                userStore.can('perform_interest_payments'),
        },
        {
            label: 'export_kista_request_kistakom',
            icon: 'fa-section',
            color: 'text-primary-accent-600',
            action: () => handleExportKistaRequest(KistaRequestType.KISTAKOM),
            condition: () =>
                contract.value.tax_calculation_enabled &&
                userStore.can('perform_interest_payments'),
        },
        {
            label: 'manage_nyala_project',
            icon: 'fa-arrow-right-arrow-left',
            color: 'text-gray-500',
            action: handleOpenConnectNyalaModal,
            condition: () =>
                userStore.can('connect_nyala_project_to_asset_or_contract') &&
                contractAssetConnectedToNyalaProject.value === false,
        },
        {
            label: 'detach_nyala_project',
            icon: 'fa-trash-can',
            color: 'text-gray-500',
            action: handleDetachNyalaProject,
            condition: () =>
                userStore.can('detach_nyala_project_from_asset_or_contract') &&
                nyalaProject.value !== null &&
                ordersCount.value === 0,
        },
    ]
)

function handleEndContract() {
    isModalOpen.value = true
    const contractEndCause = ref<ContractEndCause | null>(null)
    const disableEndContract = computed(() => {
        return !contractEndCause.value
    })
    openModal(
        {
            id: 'end_contract-modal',
            title: 'modal_title_end_contract',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: async () =>
                contractEndCause.value &&
                (await endContract(contractEndCause.value)),
        },
        () =>
            h(EndContactForm, {
                modelValue: contractEndCause,
                assetTitle: asset.value.asset_title as string,
            }),
        {
            submitButtonText: 'end_contract',
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
            submitButtonDisabled: disableEndContract,
        }
    )
}

async function handleExportTransactions() {
    const exportTransactionsResponse = await exportTransactions(
        contract.value.contract_uid as string
    )
    if (exportTransactionsResponse.data.download_url) {
        downloadFile(exportTransactionsResponse.data.download_url)
        toast.success(i18n.t('toast_success_export_transactions'))
    }
}

async function handleExportKistaRequest(type: KistaRequestType) {
    try {
        const { data } = await exportKistaRequest(
            contract.value.contract_uid as string,
            type,
            KistaServiceType.BOP
        )
        if (data.download_url) {
            downloadFile(data.download_url)
            toast.success(i18n.t('toast_success_export_kista_request'))
        }
    } catch (error) {
        toast.error(error)
    }
}

async function endContract(contractEndCause: ContractEndCause) {
    await apiEndContract(
        contractEndCause,
        contract.value.contract_uid as string
    )
        .catch((error) => {
            throw error
        })
        .then(async () => {
            toast.success(i18n.t('toast_success_end_contract'))
            isModalOpen.value = false
            await loadContract()
        })
}

const contractDetailsItems = computed<SummaryItem[]>((): SummaryItem[] => {
    return [
        {
            label: i18n.t('id'),
            value: contract.value.contract_uid,
            copy: true,
        },
        {
            label: i18n.t('contract_type'),
            value: getEnumLabel(ContractType, contract.value.contract_type),
        },
        {
            label: i18n.t('confirmed'),
            value: contract.value.confirmed,
            special: 'check',
        },
        {
            label: i18n.t('platform'),
            value: contract.value.platform_name,
            special: 'link',
            linkURL: `/platforms/${contract.value.platform_name}`,
            copy: true,
        },
        {
            label: i18n.t('contract_end_cause'),
            value: contract.value.contract_end_cause,
            condition: !!contract.value.contract_end_cause,
        },
        {
            label: i18n.t('interest_rate'),
            value: `${contract.value.interest_rate}%`,
        },
        {
            label: i18n.t('contract_duration'),
            value: getEnumLabel(
                ContractPeriodType,
                contract.value.contract_period_type
            ),
        },
        {
            label: i18n.t('first_payout'),
            value: moment(contract.value.payment_start_date).format(
                'DD.MM.yyyy'
            ),
            condition:
                contract.value.contract_period_type ===
                ContractPeriodType['CONTINUOUS'],
        },
        {
            label: i18n.t('last_payout'),
            value: moment(contract.value.payment_end_date).format('DD.MM.yyyy'),
            condition:
                contract.value.contract_period_type ===
                ContractPeriodType['FIXED'],
        },
        {
            label: i18n.t('next_payment'),
            value: moment(contract.value.next_payment_date).format(
                'DD.MM.yyyy'
            ),
            condition: !!contract.value.next_payment_date,
        },
        {
            label: i18n.t('payment_dates'),
            value: contract.value.payment_dates.join(', '),
            condition: !!contract.value.payment_dates,
        },
        {
            label: i18n.t('total_payments_count'),
            value: contract.value.contract_payments_count,
            condition:
                contract.value.contract_period_type ===
                ContractPeriodType['CONTINUOUS'],
        },
        {
            label: i18n.t('start_of_contract'),
            value: getEnumLabel(
                ContractPeriodStart,
                contract.value.contract_period_start
            ),
            condition:
                contract.value.contract_period_type ===
                ContractPeriodType['CONTINUOUS'],
        },
        {
            label: i18n.t('early_bird_promotion'),
            value: contract.value.has_early_bird_promotion,
            special: 'check',
        },
        {
            label: i18n.t('early_bird_interest_rate'),
            value: contract.value.early_bird_interest_rate
                ? `${contract.value.early_bird_interest_rate}%`
                : '-',
            condition: contract.value.has_early_bird_promotion,
        },
        {
            label: i18n.t('early_bird_deadline'),
            value: contract.value.early_bird_deadline
                ? moment(contract.value.early_bird_deadline).format(
                      'DD.MM.yyyy'
                  )
                : '-',
            condition: !!contract.value.early_bird_deadline,
        },
        {
            label: i18n.t('calculation_method'),
            value: getEnumLabel(
                InterestRateCalculationMethod,
                contract.value.interest_rate_calculation_method
            ),
        },
        {
            label: i18n.t('calculation_start_date'),
            value: getEnumLabel(
                CalculationStartDate,
                contract.value.calculation_start_date
            ),
        },
        {
            label: i18n.t('bullet_loan'),
            value: contract.value.is_bullet_loan,
            special: 'check',
        },
        {
            label: i18n.t('deferred_clearance'),
            value: contract.value.deferred_clearance,
        },
        {
            label: i18n.t('tax_calculation'),
            value: contract.value.tax_calculation_enabled,
            special: 'check',
        },
        {
            label: i18n.t('external_wallet_id'),
            value: contract.value.external_wallet_id,
            condition: !!contract.value.external_wallet_id,
            copy: true,
        },
        {
            label: i18n.t('created_on'),
            value: moment(contract.value.created_at).format('DD.MM.yyyy'),
            condition: !!contract.value.created_at,
        },
        {
            label: i18n.t('updated_at'),
            value: moment(contract.value.updated_at).format('DD.MM.yyyy'),
            condition: !!contract.value.updated_at,
        },
        {
            label: i18n.t('ended_at'),
            value: moment(contract.value.contract_ended_at).format(
                'DD.MM.yyyy'
            ),
            condition: !!contract.value.contract_ended_at,
        },
    ]
})

const { Summary: ContractSummary } = useSummary(contractDetailsItems, {
    title: 'summary',
})

const { AssetSummary } = useAssetSummary(asset)

const { ContractOrdersTable } = useContractOrdersTable(
    orders,
    ordersCount,
    assetCurrency
)

function getInterestPaymentsCount(count: number) {
    interestPaymentsCount.value = count
}

async function loadContract() {
    const id = route.params.id as string

    if (!id) {
        return
    }

    const contractRequest = await getContract(id)
    contract.value = contractRequest.data

    const assetsRequest = await getAssets()
    assets.value = assetsRequest.data.assets

    const assetRequest = await getAsset(contract.value.asset_uid as string)
    asset.value = assetRequest.data.asset
    assetCurrency.value = asset.value.currency

    const ordersRequest = await getContractOrders(id)
    orders.value = ordersRequest.data.orders
    ordersCount.value = ordersRequest.data.orders_count

    const nyalaProjectRequest = await apiGetNyalaProject(
        route.params.id as string
    )
    nyalaProject.value = nyalaProjectRequest.data.nyalaProject

    // Check if the contracts asset is connected to a Nyala project
    if (!nyalaProjectRequest.data.nyalaProject) {
        const isContractAssetConnectedToNyalaProjectRequest =
            await apiIsContractAssetConnectedToNyalaProject(
                route.params.id as string
            )
        contractAssetConnectedToNyalaProject.value =
            isContractAssetConnectedToNyalaProjectRequest.data.contract_asset_connected_to_nyala_project
    }
}

async function deleteContract(contract: ContractDetails) {
    await apiDeleteContract(contract)
    toast.success(
        i18n.t('toast_success_contract_deleted', {
            contract: contract.contract_uid,
        })
    )
    isModalOpen.value = false
    toContracts()
}

function handleDelateContract() {
    isModalOpen.value = true
    openModal(
        {
            id: 'delete_contract-modal',
            title: 'modal_title_delete_contract',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: async () => await deleteContract(contract.value),
        },
        () =>
            h(NameAndId, {
                idKey: 'contract_uid',
                nameKey: 'asset_title',
                modelValue: [contract.value],
            }),
        {
            submitButtonText: 'delete',
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

function toContracts() {
    router.push({
        name: 'contracts',
    })
}

function edit() {
    router.push({
        name: 'contract.edit',
        params: { id: contract.value.contract_uid },
    })
}

function handleOpenConnectNyalaModal() {
    if (nyalaProject.value) {
        nyalaProjectEdit.value = nyalaProject.value
    } else {
        nyalaProjectEdit.value = new NyalaProject()
        nyalaProjectEdit.value.asset_id = null
        nyalaProjectEdit.value.contract_id = contract.value.contract_uid
    }
    connectNyalaModalOpen.value = true
}

async function handleDetachNyalaProject() {
    if (nyalaProject.value) {
        await apiDetachNyalaProject(nyalaProject.value.id)
        toast.success(
            i18n.t('toast_success_nyala_project_detached_from_contract')
        )
        await loadContract()
    }
}

async function connectNyalaProjectToContract() {
    if (nyalaProject.value) {
        await apiUpdateNyalaProject(nyalaProject.value)
        toast.success(
            i18n.t('toast_success_nyala_project_connected_to_contract')
        )
        connectNyalaModalOpen.value = false
    }
    if (!nyalaProject.value && nyalaProjectEdit.value) {
        // Create new Nyala project connection
        await apiCreateNyalaProject(nyalaProjectEdit.value)
        toast.success(
            i18n.t('toast_success_nyala_project_connected_to_contract')
        )
        connectNyalaModalOpen.value = false
    }
    await loadContract()
}
watch(
    () => route.params.id,
    async () => {
        await loadContract()
    }
)

onMounted(async () => {
    await loadContract()
})
</script>

<style scoped></style>
