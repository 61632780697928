import { useSummary } from '@composables/common'
import { NyalaProject } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export function useNyalaProjectSummary(
    nyalaProject: Ref<NyalaProject | null>,
    assetContractsAllConnectedToNyalaProject: Ref<boolean | null>,
    contractAssetConnectedToNyalaProject: Ref<boolean | null>
) {
    const i18n = useI18n()

    const items = computed<SummaryItem[]>(() => {
        return [
            {
                label: i18n.t('all_contracts_connected_to_nyala_project'),
                value: assetContractsAllConnectedToNyalaProject.value,
                special: 'check',
                condition:
                    assetContractsAllConnectedToNyalaProject.value === true,
            },
            {
                label: i18n.t('contract_asset_connected_to_nyala_project'),
                value: contractAssetConnectedToNyalaProject.value,
                special: 'check',
                condition: contractAssetConnectedToNyalaProject.value === true,
            },
            {
                label: i18n.t('nyala_project_external_tokenized_asset_id'),
                value: nyalaProject.value?.external_tokenized_asset_id ?? '',
                condition:
                    nyalaProject !== null &&
                    assetContractsAllConnectedToNyalaProject.value !== true &&
                    contractAssetConnectedToNyalaProject.value !== true,
                copy: true,
            },
            {
                label: i18n.t('nyala_project_external_project_id'),
                value: nyalaProject.value?.external_project_id ?? '',
                condition:
                    nyalaProject !== null &&
                    assetContractsAllConnectedToNyalaProject.value !== true &&
                    contractAssetConnectedToNyalaProject.value !== true,
                copy: true,
            },
        ]
    })

    const { Summary: NyalaProjectSummary } = useSummary(items, {
        title: 'nyala_project',
    })

    return {
        NyalaProjectSummary,
    }
}
