import { NyalaProject } from '@src/types'
import { ApiRes, api } from '@src/utils/api/client'

export interface NyalaProjectResponse {
    success: boolean
    nyalaProject: NyalaProject | null
}

// Get
export const getNyalaProjectForAsset = (
    id: string
): ApiRes<NyalaProjectResponse> => api.get('manage/nyala-projects/asset/' + id)

export const getNyalaProjectForContract = (
    id: string
): ApiRes<NyalaProjectResponse> =>
    api.get('manage/nyala-projects/contract/' + id)

// Create
export const createNyalaProject = (
    nyalaProject: NyalaProject
): ApiRes<NyalaProjectResponse> =>
    api.post('manage/nyala-projects', nyalaProject)

// Update
export const updateNyalaProject = (
    nyalaProject: NyalaProject
): ApiRes<NyalaProjectResponse> =>
    api.put('manage/nyala-projects/' + nyalaProject.id, nyalaProject)

// Delete
export const detachNyalaProject = (id: string): ApiRes<{ success: boolean }> =>
    api.delete('manage/nyala-projects/' + id)

// Checks
export const areAssetContractsAllConnectedToNyalaProject = (
    id: string
): ApiRes<{ asset_contracts_all_connected_to_nyala_project: boolean }> =>
    api.get('manage/nyala-projects/are-asset-contracts-all-connected/' + id)

export const anyAssetContractConnectedToNyalaProject = (
    id: string
): ApiRes<{ any_asset_contract_connected_to_nyala_project: boolean }> =>
    api.get('manage/nyala-projects/any-asset-contract-connected/' + id)

export const isContractAssetConnectedToNyalaProject = (
    id: string
): ApiRes<{ contract_asset_connected_to_nyala_project: boolean }> =>
    api.get('manage/nyala-projects/is-contract-asset-connected/' + id)
